import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Hidden,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import BrandCardGrid from "../components/common/BrandCardGrid"
import BrandCardList from "../components/common/BrandCardList/BrandCardList"
import Layout from "../components/layout/Layout"
import SectionContainer from "../components/layout/SectionContainer"
import {
  MOBILE_X_PADDING,
  PINK_LIGHT_BG,
} from "../gatsby-theme-material-ui-top-layout/theme"
import { graphql, useStaticQuery } from "gatsby"
import qs from "query-string"
import { useForm, Controller, get } from "react-hook-form"
import CatalogFilter from "../components/forms/CatalogFilter"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CatalogSort, {
  CATALOG_SORT_TYPES,
} from "../components/forms/CatalogSort"

import { makeStyles } from "@material-ui/core"
import { useMobile } from "../utils/hooks"
import Dialog from "../components/common/Dialog"
import { Menu } from "@material-ui/icons"
import { hiddenOnDesktop, hiddenOnMobile } from "../utils/constans"
const useStyles = makeStyles(theme => ({
  sortButton: {
    cursor: "pointer",
  },
}))

const updateRouteQuery = filter => {
  if (window.history.pushState) {
    const dataWithoutEmptyAttrs = { ...filter }
    Object.keys(dataWithoutEmptyAttrs).forEach(
      key =>
        [undefined, null, "", []].includes(dataWithoutEmptyAttrs[key]) &&
        delete dataWithoutEmptyAttrs[key]
    )
    const query = qs.stringify(dataWithoutEmptyAttrs, { arrayFormat: "comma" })

    if (query) {
      const newurl =
        window.location.protocol +
        "//" +
        window.location.host +
        window.location.pathname +
        "?" +
        query
      window.history.pushState({ path: newurl }, "", newurl)
    }
  }
}

const computeDefaultFilter = queryString => {
  const result = {
    stage: "",
    name: "",
    location: "",
    tags: [],
    ...qs.parse(queryString, { arrayFormat: "comma" }),
  }

  if (!Array.isArray(result.tags)) {
    result.tags = [result.tags]
  }

  return result
}

const Catalog = ({ location: routeLocation }) => {
  const classes = useStyles()
  const mobile = useMobile()
  const { allBrands } = useStaticQuery(graphql`
    query {
      allBrands: allMarkdownRemark(
        filter: { fields: { collection: { eq: "brands" } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              name
              cardImage
              tags
              locationCountry
            }
            fields {
              url
              donateTarget
            }
          }
        }
      }
    }
  `)

  const [form, setForm] = React.useState("FILTER")
  const [filter, setFilter] = React.useState(
    computeDefaultFilter(routeLocation.search)
  )
  const [sort, setSort] = React.useState({
    field: CATALOG_SORT_TYPES[1].field,
    direction: CATALOG_SORT_TYPES[1].direction,
  })

  React.useEffect(() => {
    updateRouteQuery(filter)
  }, [filter])

  const items = React.useMemo(() => {
    const filtered = allBrands.edges.filter(el => {
      if (filter.name && !el.node.frontmatter.name.includes(filter.name)) {
        return false
      }

      if (
        filter.tags.length &&
        !filter.tags.some(x => el.node.frontmatter.tags.includes(x))
      ) {
        return false
      }

      if (
        filter.location &&
        el.node.frontmatter.locationCountry !== filter.location
      ) {
        return false
      }

      return true
    })

    const result = filtered.sort((a, b) => {
      if (get(a, "node." + sort.field) === get(b, "node." + sort.field)) {
        return 0
      } else if (get(a, "node." + sort.field) > get(b, "node." + sort.field)) {
        return 1
      } else {
        return -1
      }
    })

    return sort.direction === "ASC" ? result : result.reverse()
  }, [filter, sort])

  const openControls = React.useMemo(
    () => ({
      FILTER: () => setForm("FILTER"),
      SORT: () => setForm("SORT"),
    }),
    [form]
  )

  return (
    <Layout
      navbarRightSlot={
        !mobile && (
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              onClick={openControls.FILTER}
              className={classes.sortButton}
            >
              <Typography variant="h6">Filtruj</Typography>
              <Box ml={1} />
              <ExpandMoreIcon color="action" />
            </Box>

            <Box ml={4} />

            <Box
              display="flex"
              alignItems="center"
              onClick={openControls.SORT}
              className={classes.sortButton}
            >
              <Typography variant="h6">Sortuj</Typography>
              <Box ml={1} />
              <ExpandMoreIcon color="action" />
            </Box>
          </Box>
        )
      }
      navbarLeftSlot={
        mobile && (
          <MobileFilters
            {...{ sort, setSort, form, openControls, filter, setFilter }}
          />
        )
      }
    >
      <Grid container>
        {!mobile && (
          <Grid item xs={12} md={4} lg={3}>
            <Box
              px={mobile ? MOBILE_X_PADDING : 6}
              py={8}
              bgcolor={PINK_LIGHT_BG}
              height="100%"
            >
              {form === "FILTER" ? (
                <CatalogFilter {...{ filter, setFilter }} />
              ) : (
                <CatalogSort {...{ sort, setSort }} />
              )}
            </Box>
          </Grid>
        )}

        <Grid item xs={12} md={8} lg={9}>
          <SectionContainer py={4}>
            <BrandCardList items={items} variant="square" />
          </SectionContainer>
        </Grid>
      </Grid>
    </Layout>
  )
}

export default Catalog

const MobileFilters = ({
  form,
  openControls,
  filter,
  setSort,
  sort,
  setFilter,
}) => {
  const [open, setOpen] = React.useState(false)
  React.useEffect(() => {
    open && setOpen(false)
  }, [filter, sort])

  return (
    <Dialog
      contentProps={{ bgcolor: PINK_LIGHT_BG }}
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      element={
        <IconButton>
          <Menu />
        </IconButton>
      }
    >
      <Box display="flex" alignItems="center">
        <Box display="flex" alignItems="center" onClick={openControls.FILTER}>
          <Typography variant="h6">Filtruj</Typography>
          <Box ml={1} />
          <ExpandMoreIcon color="action" />
        </Box>

        <Box ml={4} />

        <Box display="flex" alignItems="center" onClick={openControls.SORT}>
          <Typography variant="h6">Sortuj</Typography>
          <Box ml={1} />
          <ExpandMoreIcon color="action" />
        </Box>
      </Box>
      <Box mt={MOBILE_X_PADDING}>
        {form === "FILTER" ? (
          <CatalogFilter {...{ filter, setFilter }} />
        ) : (
          <CatalogSort {...{ sort, setSort }} />
        )}
      </Box>
    </Dialog>
  )
}
