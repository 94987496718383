import {
  DialogContent,
  Dialog as MuiDialog,
  Box,
  IconButton,
} from "@material-ui/core"
import { Close } from "@material-ui/icons"
import React from "react"
import { useMobile } from "../../utils/hooks"

const Dialog = ({
  element,
  children,
  open: propOpen,
  onClose: propOnClose,
  onOpen: propOnOpen,
  contentProps = {},
}) => {
  const [open, setOpen] = React.useState(false)
  const mobile = useMobile()

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {element &&
        React.cloneElement(element, {
          onClick: propOnOpen ? propOnOpen : handleClickOpen,
        })}

      <MuiDialog
        fullScreen={mobile}
        open={typeof propOpen !== "undefined" ? propOpen : open}
        onClose={propOnClose ? propOnClose : handleClose}
      >
        <Box position="relative" {...contentProps} clone>
          <DialogContent>
            <Box position="absolute" right={8} top={8} clone>
              <IconButton onClick={propOnClose ? propOnClose : handleClose}>
                <Close fontSize="large" />
              </IconButton>
            </Box>
            <Box>{children}</Box>
          </DialogContent>
        </Box>
      </MuiDialog>
    </>
  )
}

export default Dialog
