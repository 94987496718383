import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import SearchIcon from "@material-ui/icons/Search"
import CloseIcon from "@material-ui/icons/Close"
import { graphql, useStaticQuery } from "gatsby"
import qs from "query-string"
import { useForm, Controller } from "react-hook-form"
import ReactHookFormSelect from "../common/ReactHookFormSelect"

const CatalogFilter = ({ filter, setFilter }) => {
  const { allTags } = useStaticQuery(graphql`
    query {
      allTags: allMarkdownRemark(
        filter: { fields: { collection: { eq: "tags" } } }
      ) {
        nodes {
          id
          frontmatter {
            name
            label
            image
            icon
          }
        }
      }
    }
  `)

  const { control, handleSubmit, register } = useForm({
    defaultValues: {
      ...filter,
      tags: allTags.nodes.map(x =>
        filter.tags.includes(x.frontmatter.name) ? x.frontmatter.name : false
      ),
    },
  })

  const onSubmit = data =>
    setFilter({
      ...data,
      tags: data.tags.filter(Boolean),
    })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" gutterBottom>
        Projekt
      </Typography>

      <TextField
        label="Nazwa projektu"
        fullWidth
        variant="filled"
        name="name"
        inputRef={register}
      />

      <Box mt={4} />

      <Typography variant="h6" gutterBottom>
        Misja projektu
      </Typography>

      <FormGroup>
        {allTags.nodes.map((x, index) => (
          <Controller
            key={`tags.${index}`}
            defaultValue={false}
            control={control}
            name={`tags.${index}`}
            render={props => (
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    name={props.name}
                    checked={!!props.value}
                    onChange={e =>
                      props.onChange(
                        !e.target.checked ? false : x.frontmatter.name
                      )
                    }
                  />
                }
                label={x.frontmatter.label}
              />
            )}
          />
        ))}
      </FormGroup>

      <Box mt={4} />

      <Typography variant="h6" gutterBottom>
        Lokalizacja
      </Typography>
      <ReactHookFormSelect
        id="location"
        name="location"
        control={control}
        fullWidth
        variant="filled"
      >
        <MenuItem value={""}>⠀</MenuItem>
        <MenuItem value="PL">Polska</MenuItem>
        <MenuItem value="DE">Niemcy</MenuItem>
      </ReactHookFormSelect>

      <Box mt={4} />

      <Box display="flex">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<SearchIcon />}
          fullWidth
          type={"submit"}
        >
          Szukaj
        </Button>
        <Box ml={2} />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<CloseIcon />}
          fullWidth
          type="reset"
        >
          Wyczyść
        </Button>
      </Box>
    </form>
  )
}

export default CatalogFilter
