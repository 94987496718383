import { FormControl, InputLabel, Select } from "@material-ui/core"
import React from "react"
import { Controller } from "react-hook-form"

const ReactHookFormSelect = ({
  name,
  label,
  control,
  defaultValue,
  children,
  selectProps,
  ...props
}) => {
  const labelId = `${name}-label`
  return (
    <FormControl {...props}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <Controller
        as={
          <Select labelId={labelId} label={label} {...selectProps}>
            {children}
          </Select>
        }
        name={name}
        control={control}
        defaultValue={defaultValue}
      />
    </FormControl>
  )
}

export default ReactHookFormSelect
