import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@material-ui/core"
import React from "react"
import SortIcon from "@material-ui/icons/Sort"
import CloseIcon from "@material-ui/icons/Close"

export const CATALOG_SORT_TYPES = [
  "Czas do końca",
  { label: "najmniej", field: "frontmatter.dateToEnd", direction: "ASC" },
  { label: "najwięcej", field: "frontmatter.dateToEnd", direction: "DSC" },
  "Zebrana kwota",
  { label: "najmniej", field: "fields.donateTarget", direction: "ASC" },
  { label: "najwięcej", field: "fields.donateTarget", direction: "DSC" },
  "Data dodania",
  { label: "od najnowszych", field: "frontmatter.date", direction: "ASC" },
  { label: "od najstarszych", field: "frontmatter.date", direction: "DSC" },
  { label: "wkrótce start", field: "frontmatter.dateStart", direction: "ASC" },
]

const CatalogSort = ({ sort, setSort }) => {
  const [value, setValue] = React.useState(JSON.stringify(sort))

  const handleChange = (_, v) => setValue(v)

  const submit = () => setSort(JSON.parse(value))

  const reset = () => setValue(sort)
  console.log(value)
  return (
    <Box display="flex" flexDirection="column">
      <RadioGroup value={value} onChange={handleChange}>
        {CATALOG_SORT_TYPES.map((x, i) => {
          if (typeof x === "string") {
            return (
              <>
                {i !== 0 && <Box mt={4} />}
                <Typography variant="h6" gutterBottom>
                  {x}
                </Typography>
              </>
            )
          } else {
            return (
              <FormControlLabel
                value={JSON.stringify({
                  field: x.field,
                  direction: x.direction,
                })}
                control={<Radio color="primary" />}
                label={x.label}
              />
            )
          }
        })}
      </RadioGroup>

      <Box mt={4} />

      <Box display="flex">
        <Button
          variant="contained"
          color="secondary"
          startIcon={<SortIcon />}
          fullWidth
          onClick={submit}
        >
          Filtruj
        </Button>
        <Box ml={2} />
        <Button
          variant="contained"
          color="secondary"
          startIcon={<CloseIcon />}
          fullWidth
          onClick={reset}
        >
          Wyczyść
        </Button>
      </Box>
    </Box>
  )
}

export default CatalogSort
